import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { AuthenticatedGuard } from './authenticated/authenticated.guard';
import { ConfigService } from './config.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    title: 'Login',
  },
  {
    path: '',
    loadChildren: () => import('./authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
    canMatch: [AuthenticatedGuard],
    title: 'App',
  },
  {
    path: '**',
    redirectTo: '/auth/login',
  },
];

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    readonly cs: ConfigService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${this.cs.config.title} - ${title}`);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: AppTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
