/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Observable, tap } from 'rxjs';
import { API_INTERCEPTOR_PROVIDER, ApiInterceptor } from './api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthRepository } from './auth.repository';
import { AuthModule } from './auth/auth.module';
import { AuthenticatedGuard, AuthenticatedGuardError } from './authenticated/authenticated.guard';
import { IConfig } from './config.interfaces';
import { ConfigService } from './config.service';
import { ApiModule } from './generated/api/api.module';
import { ApiService } from './generated/api/services';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe);
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    console.log('error', error);

    if (error instanceof AuthenticatedGuardError) {
      console.log('AuthenticatedGuardError', this);
    }
    console.error('Error from global error handler', error);
  }
}

export function configInitFactory(cs: ConfigService, api: ApiService): () => Observable<IConfig> {
  return () =>
    cs.init().pipe(
      tap((config) => {
        api.rootUrl = config.apiUrl;
      }),
    );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule.forRoot({
      rootUrl: undefined,
    }),
    BrowserAnimationsModule,
    SharedModule,
    AuthModule,
  ],
  providers: [
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    AuthenticatedGuard,
    AuthRepository,
    ConfigService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configInitFactory,
      deps: [ConfigService, ApiService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
