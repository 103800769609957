/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, first, map, switchMap, tap } from 'rxjs';
import { AuthRepository } from './auth.repository';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private authRepo: AuthRepository,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Apply the headers
    return this.authRepo.bearer$.pipe(
      first(),
      map((token: string) =>
        req.clone({
          setHeaders: {
            Authorization: token,
          },
        }),
      ),
      switchMap((req) => next.handle(req)),
      tap({
        next: (x) => x,
        error: (err) => {
          if (err.status == 401) {
            this.router.navigate(['/', 'auth', 'login']);
          }
          // FIXME: Handle this err
          console.error(`Error performing request, status code = ${err.status}`);
        },
      }),
    );
  }
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};
