import { Component, OnInit } from '@angular/core';
import { ConfigService } from './config.service';
import { ThemeManagerService } from './theme-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    readonly cs: ConfigService,
    readonly tms: ThemeManagerService,
  ) {}

  ngOnInit(): void {
    this.tms.applyDefault();
  }
}
