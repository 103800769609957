import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthRepository } from '../auth.repository';

export class AuthenticatedGuardError extends Error {}

@Injectable()
export class AuthenticatedGuard {
  constructor(
    private readonly authRepo: AuthRepository,
    private readonly router: Router,
  ) {}

  canMatch(): Observable<boolean> {
    return this.authRepo.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn === true) {
          return of(true);
        } else {
          return this.router.navigate(['/auth', 'login']);
          // return throwError(() => new AuthenticatedGuardError('Unauthorized'));
        }
      }),
    );
  }
}
