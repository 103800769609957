@if (cs.config$ | async; as config) {
  @if (config.error) {
    <div class="config-service-error">
      <h1>😩</h1>
      <h2>Konfiguration konnte nicht geladen werden!</h2>
      <h3>Bitte wenden sie sich an Ihren Administrator.</h3>
      <p>{{ config.error }}</p>
    </div>
  } @else {
    <router-outlet></router-outlet>
  }
}
