/// <reference types="@angular/localize" />

import { ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';
import { Settings } from 'luxon';
import { AppModule } from './app/app.module';

Settings.defaultZone = 'utc';
Settings.defaultLocale = 'de';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    devTools({
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  });
